@import url("https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&display=swap");

@font-face {
  font-family: anton;
  src: url(../public/fonts/Anton-Regular.ttf);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --7d-dark: #000000;
  --7d-white: #ffffff;
  --7d-gray-20: #202020;
  --7d-gray-70: #707070;
  --7d-gray-19: #191919;
  --7d-gray-39: #393939;
  --7d-lightorange: #ffbe98;
  --7d-orange: #f15625;
  --7d-green: #9dcb3b;
  --7d-yellow: #e9e748;
  --7d-blue: #5093ab;
  --7d-border-gray: #707070;
}
@layer base {
  html {
    @apply text-[62.5%] max-3xl:text-[50%] max-2xl:text-[45%] max-md:text-[40%] max-xs:text-[62.5%] scroll-smooth h-full;
  }
  body {
    @apply h-full;
  }
}
@layer components {
  .primary-heading {
    @apply text-[3.2rem] font-blinker font-normal text-7d-lightorange max-xs:text-[2rem];
  }
  .secondary-heading {
    @apply text-[3.2rem] font-blinker font-normal text-7d-white max-xs:text-[2rem];
  }
  .projects-heading {
    @apply text-[3.2rem] font-blinker font-normal text-7d-orange uppercase mb-[4.5rem];
  }
  .button {
    @apply w-[22.1rem] h-[5rem] text-[#ffffff] transition-all duration-1000  border-[0.05rem] border-7d-white text-[3.2rem] font-normal rounded-2xl relative overflow-hidden;
  }
  .button:hover {
    @apply text-[#282828] border-none font-semibold scale-100;
  }
  .button::before {
    @apply content-[""] absolute left-[-100px] w-0 h-full skew-x-[-65deg] z-[-1] transition-[width]  duration-500  top-0;
    background: rgb(40, 40, 40);
    background: linear-gradient(
      50deg,
      rgba(40, 40, 40, 1) 0%,
      rgba(233, 231, 72, 1) 20%,
      rgba(157, 203, 59, 1) 30%,
      rgba(83, 145, 168, 1) 45%,
      rgba(241, 86, 37, 1) 60%
    );
  }
  .button:hover::before {
    @apply w-[250%];
  }
  .video-overlay::after {
    @apply content-[""] block w-full h-full absolute top-0 left-0 z-[2];
  }
  .video-overlay-desktop::after {
    @apply bg-[#000000b6];
  }
  .video-overlay-mobile::after {
    @apply bg-[#000000e3];
  }
}
@layer utilities {
  .border-red {
    border: 1px solid red;
  }
  .border-blue {
    border: 1px solid blue;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Blinker", sans-serif;
  color: var(--7d-white);
}
img {
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #1c1c1c;
}

::-webkit-scrollbar-thumb {
  background: #ff4f00;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(221, 63, 14);
}

.primary-btn:after {
  content: "";
  width: 0%;
  height: 100%;
  background: linear-gradient(
    50deg,
    rgba(40, 40, 40, 1) 0%,
    rgba(233, 231, 72, 1) 20%,
    rgba(157, 203, 59, 1) 30%,
    rgba(83, 145, 168, 1) 45%,
    rgba(241, 86, 37, 1) 60%
  );
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 0.8s ease;
}
.primary-btn:hover::after {
  width: 100%;
}
.sevendlogo:hover svg g {
  fill: url(#animatedGradient);
}

.page-close::after {
  content: "";
  background: url(images/back.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 2.1rem;
  right: 101.5%;
  pointer-events: none;
}

@media (max-width: 1600px) {
  .contact-bliss:after {
    width: 23rem;
    height: 23rem;
  }
}
.aboutimg {
  scale: 1.3;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* . */

.colored-toast.swal2-icon-success {
  background-color: #020202 !important;
  padding: 5% !important;
  color: greenyellow !important;
  font-size: 20px !important;
}
.colored-toast.swal2-icon-error{
  background-color: #020202 !important;
  padding: 5% !important;
  color: rgb(255, 61, 47) !important;
  font-size: 20px !important;
}